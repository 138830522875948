// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      footerScratch: file(relativePath: {eq: "footer-scratch.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 1920, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecoCircle: file(relativePath: {eq: "eco-circle.png"}) {
        childImageSharp {
          fixed (width: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
    <footer>
      
      <div>

      <Img fluid={data.footerScratch.childImageSharp.fluid} alt="Footer Scratch" />
      
      <div className="flex flex-wrap bg-gray-800 p-3 text-white text-xs">
        <div className="w-1/2 bottom-0 text-left ">
          Design by <a class="underline" href="https://www.sarahbovet.com/">Sarah Bovet</a> Code by <a class="underline" href="https://www.graaaf.com">GRAAAF</a>
        </div> 
        
        <div className="w-full md:w-1/2 text-right">
          <div className="float-left md:float-right ml-0 md:ml-5 mt-10 md:mt-0">
            <a href="https://profiles.eco/dpollardassociates?ref=tm" rel="noopener">
            <Img fixed={data.ecoCircle.childImageSharp.fixed} /></a>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 float-right text-left md:text-right">
            Duncan Pollard &amp; Associates is a proud user of the .eco top-level domain. 
          </div>
        </div> 
      </div>



      </div>
 
    
    </footer>
    </>
  )
}

export default Footer
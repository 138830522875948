/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import {Helmet} from 'react-helmet';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children, backgroundColor }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
        <Helmet>
        </Helmet>
      

        <div className="flex flex-wrap h-screen" >
          <div className="max-w-lg mx-auto mt-20 mb-5 p-10 md:p-0 text-center ">
            <Header siteTitle={data.site.siteMetadata.title} />
          </div>
          <div className="w-full flex-grow mx-auto">
            <main>{children}</main>
          </div>
          <div className="w-full self-end">
            <Footer />
          </div>
        </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

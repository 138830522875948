import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"


const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    {
      logoImage: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fluid (maxWidth: 512) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      logoImageFixed: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fixed (width: 312) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  Header.propTypes = {
    siteTitle: PropTypes.string,
  }
  
  Header.defaultProps = {
    siteTitle: ``,
  }
  
  
  return (
    <>
      <header>
            <h1 className="pb-5">
            <Link
              to="/"
            >
            <div className="max-w-xs md:max-w-none">
            <Img fluid={data.logoImage.childImageSharp.fluid} alt="logo Duncan" /></div>
            </Link>
            </h1>
          <p className="mx-10" >A consultancy for business, civil society, communities and academia.  Offering support, coaching and guidance on all things sustainability related.</p>
      </header>
    </>
  )
}


export default Header
